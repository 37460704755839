import React, { useContext, useReducer } from "react";

import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { unique } from "utils/index";

type NewCart = {
    type: "newCart";
    tenantId: string;
};
type ResetCart = {
    type: "resetCart";
    tenantId: string;
};
type SetCartItems = {
    type: "setCartItems";
    tenantId: string;
    categoryId: string | undefined;
    slots: Slot[];
    addOns: AddOn[];
};
type SetCategoryIdAction = {
    type: "setCategoryId";
    tenantId: string;
    categoryId: string;
};
type ServiceMode = "DAILY_SERVICE" | "HOURLY_SERVICE";
type Slot = {
    serviceMode: ServiceMode;
    time: dayjs.Dayjs;
    duration: number;
    resourceId: string;
    serviceId: string;
    resourceName: string;
    price: number;
    deposit: number;
    expiry?: dayjs.Dayjs;
    startTime?: string;
    endTime?: string;
};
type SetSlots = {
    type: "setSlots";
    tenantId: string;
    slots: Slot[];
};
type ToggleSlot = {
    type: "toggleSlot";
    tenantId: string;
    slot: Slot;
};
type AddOn = {
    key: string;
    date: dayjs.Dayjs;
    uid: string | undefined;
    quantity: number | undefined;
    name: string | undefined;
    price: number | undefined;
};
type SetAddOns = {
    type: "setAddOns";
    tenantId: string;
    addOns: AddOn[];
};
type SetPromocode = {
    type: "setPromocode";
    tenantId: string;
    promocode?: string;
};
type Cart = {
    tenantId: string;
    categoryId?: string;
    slots: Slot[];
    addOns: AddOn[];
    promocode?: string;
};
type CartAction =
    | NewCart
    | ResetCart
    | SetCartItems
    | SetCategoryIdAction
    | SetSlots
    | ToggleSlot
    | SetAddOns
    | SetPromocode;
type CartDispatch = React.Dispatch<CartAction>;
type CartState = {
    carts: Cart[];
};

const CartContext = React.createContext<
    | {
          state: CartState;
          dispatch: CartDispatch;
      }
    | undefined
>(undefined);

const getNewAddOns = (addOns: AddOn[], slots: Slot[]): AddOn[] => {
    const uniqueNewSlotDates = unique(
        slots.map((s) => s.time.startOf("d").toISOString()),
    );
    const uniqueNewAddOnDates = unique(addOns.map((a) => a.date.toISOString()));
    const addOnDatesToAdd = uniqueNewSlotDates.filter(
        (s) => !uniqueNewAddOnDates.includes(s),
    );
    const newAddOns = addOns;
    addOnDatesToAdd.forEach((d: string) => {
        newAddOns.push({
            key: uuidv4(),
            date: dayjs(d),
            uid: undefined,
            quantity: 1,
            name: undefined,
            price: undefined,
        });
    });
    return newAddOns.filter((a) =>
        uniqueNewSlotDates.includes(a.date.toISOString()),
    );
};

const reducer = (state: CartState, action: CartAction): CartState => {
    switch (action.type) {
        case "newCart":
            return {
                carts: [
                    ...state.carts,
                    {
                        tenantId: action.tenantId,
                        categoryId: undefined,
                        slots: [],
                        addOns: [],
                        promocode: undefined,
                    },
                ],
            };
        case "resetCart":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              tenantId: c.tenantId,
                              categoryId: undefined,
                              slots: [],
                              addOns: [],
                          }
                        : c,
                ),
            };
        case "setCartItems":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              tenantId: c.tenantId,
                              categoryId: action.categoryId,
                              slots: action.slots,
                              addOns: action.addOns,
                          }
                        : c,
                ),
            };
        case "setCategoryId":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              ...c,
                              categoryId: action.categoryId,
                          }
                        : c,
                ),
            };
        case "toggleSlot":
            return {
                carts: state.carts.map((c) => {
                    if (c.tenantId !== action.tenantId) return c;
                    const newSlots = [...c.slots];
                    const i = newSlots.findIndex(
                        (s) =>
                            s.serviceId === action.slot.serviceId &&
                            s.resourceId === action.slot.resourceId &&
                            s.time.unix() === action.slot.time.unix() &&
                            s.duration === action.slot.duration,
                    );
                    if (i < 0) newSlots.push(action.slot);
                    else newSlots.splice(i, 1);
                    return {
                        ...c,
                        slots: newSlots,
                        addOns: getNewAddOns(c.addOns, newSlots),
                    };
                }),
            };
        case "setSlots":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              ...c,
                              slots: action.slots,
                              addOns: getNewAddOns(c.addOns, action.slots),
                          }
                        : c,
                ),
            };
        case "setAddOns":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              ...c,
                              addOns: getNewAddOns(action.addOns, c.slots),
                          }
                        : c,
                ),
            };
        case "setPromocode":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? { ...c, promocode: action.promocode }
                        : c,
                ),
            };
    }
};

export const useCart = (): {
    state: CartState;
    dispatch: React.Dispatch<CartAction>;
} => {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error("useCart must be used within a CartProvider");
    }
    return context;
};

type CartProviderProps = {
    children: React.ReactNode;
};
export const CartProvider = ({ children }: CartProviderProps): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, {
        carts: [],
    });
    const value = { state, dispatch };

    return (
        <CartContext.Provider value={value}>{children}</CartContext.Provider>
    );
};
